import { GET_ALL_NEWSPAPER ,GET_NEWS_DETAIL} from "../actions/types";


const initialState = {
    allNewspaper: null,
    newsDetail: null
};

const newspaperReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NEWSPAPER:
      return {
        ...state,
        allNewspaper: action.payload,
      };

      case GET_NEWS_DETAIL:
        return {
          ...state,
          newsDetail: action.payload,
        };
      

    default:
      return state;
  }
};
export default newspaperReducer;
