import {
    GET_ORDER_HISTORY,
    SET_ORDER_HISTORY_DETAIL,
    SET_ORDER_HISTORY_LIST,
    CLAIM_INSURANCE
  } from "../actions/types";
  
  const initialState = {
    orderHistoryData: null,
    orderHistoryRequestDetail:null,
    orderHistoryList: [],
    insuranceClaim: null,
  };
  
  const orderHisoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_ORDER_HISTORY_LIST:
      return {
        ...state,
        orderHistoryList: action.payload.data.orders,
      };
      case SET_ORDER_HISTORY_DETAIL:
        return {
          ...state,
          orderHistoryRequestDetail: action.payload,
        };
        case CLAIM_INSURANCE :
          return{
            ...state,
            insuranceClaim : action.payload
          }
      default:
        return state;
    }
  };
  export default orderHisoryReducer;
  