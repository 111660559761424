import { LIVE_RATES } from "../actions/types";


const initialState = {
    liveRatesData: null,
};

const liveRatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIVE_RATES:
            return {
                ...state,
                liveRatesData: action.payload,
            };

        default:
            return state;
    }
};
export default liveRatesReducer;
