import {
  GET_ALL_PRODUCTS,
  GET_PRODUCT_DETAIL,
  ADD_PRODUCT_CART,
  VIEW_PRODUCT_CART,
  REMOVE_PRODUCT,
  COUPON_APPLAY,
  FAV_PRODUCTS_LIST,
  ADD_FAV_PRODUCTS_LIST,
  ADD_RECENT_PRODUCT,
  LIST_REVIEWS,
  VIEW_RECENT_PRODUCT,
  ADD_REVIEWS,
  ORDER_HISTORY,
  COUPON_REMOVE
} from "../actions/types";

const initialState = {
  allProducts: null,
  detailProduct: null,
  productCart: null,
  cartView: null,
  removeProduct: null,
  couponResult: null,
  productFav: null,
  addFavPro: null,
  recentProAdd: null,
  recentProView: null,
  reviewsList: null,
  addReview: null,
  orderHistory : null,
  removeCoupon : null
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload,
      };
    case GET_PRODUCT_DETAIL:
      return {
        ...state,
        detailProduct: action.payload,
      };
    case ADD_PRODUCT_CART:
      return {
        ...state,
        productCart: action.payload,
      };
    case VIEW_PRODUCT_CART:
      return {
        ...state,
        cartView: action.payload,
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        removeProduct: action.payload,
      };

    case COUPON_APPLAY:
      return {
        ...state,
        couponResult: action.payload,
      };
      case COUPON_REMOVE : 
      return{
        ...state,
        removeCoupon : action.payload
      }
    case FAV_PRODUCTS_LIST:
      return {
        ...state,
        productFav: action.payload,
      };
    case ADD_FAV_PRODUCTS_LIST:
      return {
        ...state,
        addFavPro: action.payload,
      };
    case ADD_RECENT_PRODUCT:
      return {
        ...state,
        recentProAdd: action.payload,
      };
    case VIEW_RECENT_PRODUCT:
      return {
        ...state,
        recentProView: action.payload,
      };

    case LIST_REVIEWS:
      return {
        ...state,
        reviewsList: action.payload,
      };
      case ADD_REVIEWS:
        return {
          ...state,
          addReview: action.payload,
        };

        case ORDER_HISTORY :
          return {
            ...state,
            orderHistory : action.payload
          }

    default:
      return state;
  }
};
export default productReducer;
