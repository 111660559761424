import { STOP_LOADER, START_LOADER } from "./types";

export const Startloader = (data) => {
    return (dispatch) => {
      dispatch({
        type: START_LOADER,
        payload: data,
      });
    };
  };
  export const Stoploader = (data) => {
    return (dispatch) => {
      dispatch({
        type: STOP_LOADER,
        payload: data,
      });
    };
  };