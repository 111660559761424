import {
 
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  SIGNUP_USER_OTP_SUCCESS,
  SIGNUP_SUBMIT_PROFILE_SUCCESS,
} from "../actions/types";
import Cookies from "js-cookie";
import { act } from "react-dom/test-utils";

const initialState = {
 
  signupData: null,
  signupErrorData: null,
  signupDataOtp: null,
  signupProfileData: null,
};

const SignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS:
      return {
        ...state,
        signupData: action.payload,
      };
      case SIGNUP_USER_ERROR:
        return {
          ...state,
          signupErrorData: action.payload,
        };
    case SIGNUP_USER_OTP_SUCCESS:
      return {
        ...state,
        signupDataOtp: action.payload,
      };
    case SIGNUP_SUBMIT_PROFILE_SUCCESS:
      return {
        ...state,
        signupProfileData: action.payload,
      };
    default:
      return state;
  }
};
export default SignupReducer;
