import { GET_ALL_FEEDBACK, SAVE_FEEDBACK } from "../actions/types";


const initialState = {
    allFeedback: null,
    savefeedback:null
};

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_FEEDBACK:
            return {
                ...state,
                allFeedback: action.payload,
            };
        case SAVE_FEEDBACK:
            return {
                ...state,
                savefeedback: action.payload,
            };


        default:
            return state;
    }
};
export default feedbackReducer;
