import {
 
  GET_USER_PROFILE_DATA,
  
} from "../actions/types";
import Cookies from "js-cookie";
import { act } from "react-dom/test-utils";

const initialState = {
  getProfileData: null,
  
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_DATA:
      return {
        ...state,
        getProfileData: action.payload,
      };
  
    default:
      return state;
  }
};
export default profileReducer;
