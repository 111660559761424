import {
  FCM_TOKEN,
  GENERATE_REFRESH_TOKEN,
  GENERATE_ACCESS_TOKEN,
  CREATE_GUEST_USER_ID,
  GET_SPECIES,
  INTERESTED_SPECIES,
  HEADER_CLASS,
  GLOBAL_SEARCH_WORD,
  LOGIN_USER_SUCCESS,
  GET_TRANS_CHAGRGES,
  GET_PROFILE_DATA,
  LOGIN_USER_OTP_SUCCESS,
  PAYMENT_TYPE
  
} from "../actions/types";
import Cookies from "js-cookie";

const initialState = {
  fcm: null,
  refreshToken: null,
  accessToken: null,
  speciesGet: null,
  guestUserId: null,
  interSpecies: null,
  headerData: null,
  global: null,
  login: null,
  transactionCharges: null,
  profile: null,
  loginOtp:null,
  payment : null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case FCM_TOKEN :
      return{
        ...state,
        fcm: action.payload
      };
    case GENERATE_REFRESH_TOKEN:
      Cookies.set("refresh_token_develop", action.payload.data.refreshToken, {
        expires: 4,
      });

      return {
        ...state,
        refreshToken: action.payload,
      };

    case GENERATE_ACCESS_TOKEN:
      Cookies.set("access_token_develop", action.payload.data.accessToken, {
        expires: 4,
      });
      return {
        ...state,
        accessToken: action.payload,
      };

    case CREATE_GUEST_USER_ID:
       Cookies.set("user_id_develop", action.payload.data.guestUserID, { expires: 4 });
      return {
        ...state,
        guestUserId: action.payload,
      };
    case GET_SPECIES:
      return {
        ...state,
        speciesGet: action.payload,
      };
    case INTERESTED_SPECIES:
      return {
        ...state,
        interSpecies: action.payload,
      };
    case HEADER_CLASS:
      return {
        ...state,
        headerData: action.payload,
      };
    case GLOBAL_SEARCH_WORD:
      return {
        ...state,
        global: action.payload,
      };
    case LOGIN_USER_SUCCESS:
      if(action.payload !== null) {
      // Cookies.set("user_id", action.payload.data.customerId, { expires: 4 });
      }

      return {
        ...state,
        login: action.payload,
      };
      case LOGIN_USER_OTP_SUCCESS:
        if(action.payload !== null) {
         Cookies.set("user_id_develop", action.payload.data.customerId, { expires: 4 });
        }
  
        return {
          ...state,
          loginOtp: action.payload,
        };

    case GET_TRANS_CHAGRGES:
      return {
        ...state,
        transactionCharges: action.payload,
      };

      case GET_PROFILE_DATA:
      return {
        ...state,
        profile: action.payload,
      };
      case PAYMENT_TYPE:
      return {
        ...state,
        payment: action.payload,
      };
    default:
      return state;
  }
};
export default loginReducer;
