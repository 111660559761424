import React from 'react'
import {Startloader,Stoploader} from '../../actions/loaderAction'
import { connect } from "react-redux";

const Loader = (props) => {
    return (
        <>
    
             {props.startLoader?
             ''
        //     <div id="preloder">
        //     <div className="loader"></div>
        // </div>
           :''  }

             </>

    )
}
const mapDispatchToProps = {
    // Startloader,
    // Stoploader
   
  };
  const mapStateToProps = (state) => ({
    startLoader: state.loaderReducer.startLoader,
  
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Loader);
