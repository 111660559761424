import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import "./App.css";
import "./index.css";
import Cookies from "js-cookie";
import { Offline, Online } from "react-detect-offline";

const Rootlayout = React.lazy(() => import("./Layout/RootLayout/RootLayout"));

const Checkout = React.lazy(() => import("components/Checkout/Checkout.js"));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);
const Success = React.lazy(() =>
  import("components/StaticPages/SuccessOrder.js")
);
const Failure = React.lazy(() =>
  import("components/StaticPages/FailureOrder.js")
);
const PaymentFailed = React.lazy(() =>
  import("components/StaticPages/PaymentDuesFailed.js")
);
const PaymentSuccess = React.lazy(() =>
  import("components/StaticPages/PaymentDuesSuccess.js")
);
function App(props) {
  useEffect(() => {
    if (!!props.error) {
      if (props.error.message === "Network Error") {
        Cookies.remove("access_token_develop");
        window.location.href = "/";
      }
    }
  }, [props.error]);

  

  return (
    <div>
      {/* <Offline>
        <div className="search-notfound" style={{ marginTop: "100px" }}>
          <div className="container">
            <div className="rectangle_curve mb-4">
              <div className="row">
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <div className="notfoundData">
                    <h3 className="mb-3">{"You Are Offline!"}</h3>
                   
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 text-center">
                  <img src="./assets/images/notfound.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offline> */}
      {/* <Online> */}
        {" "}
        <Router history={history} basename={process.env.PUBLIC_URL}>
          <Suspense fallback={loading()}>
            <Switch>
            <Route
                key={Math.random()}
                path="/order-success"
                name="success"
                render={(props) => <Success {...props} />}
              />
              <Route
                key={Math.random()}
                path="/order-failed"
                name="failed"
                render={(props) => <Failure {...props} />}
              />
                 <Route
                key={Math.random()}
                path="/payment-failed"
                name="Paymentfailed"
                render={(props) => <PaymentFailed {...props} />}
              />
                  <Route
                key={Math.random()}
                path="/payment-success"
                name="PaymentSuccess"
                render={(props) => <PaymentSuccess {...props} />}
              />
              <Route
                key={Math.random()}
                exact
                path="/checkout"
                name="checkout"
                render={(props) => <Checkout {...props} />}
              />
              <Route
                key={Math.random()}
                path="/"
                name="main page"
                render={(props) => <Rootlayout {...props} />}
              />
             
            </Switch>
          </Suspense>
        </Router>
      {/* </Online> */}
    </div>
  );
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
