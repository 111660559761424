import { GET_DASHBOARD_DATA ,GET_WHITEPAPER_DETAIL,GET_SEARCH_RESULT,GET_SUBSCRIBE} from "../actions/types";
import Cookies from "js-cookie";

const initialState = {
  dashboardData: null,
  whitepaperDetail: null,
  subscribe : null,
  clearDash : null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
        clearDash : action.cleardash,
        commodityData: action.payload &&  action.payload.commodityMaster
      };
      case GET_WHITEPAPER_DETAIL:
        return{
          ...state,
          whitepaperDetail: action.payload
        }
        case GET_SEARCH_RESULT :
          return{
            ...state,
            searchResult : action.payload
          }

          case GET_SUBSCRIBE :
            return{
              ...state,
              subscribe : action.payload
            }

    default:
      return state;
  }
};
export default dashboardReducer;
