import {
  GET_TRANS_CHAGRGES,
  SUBMIT_PAYMENT_FORM,
  SUBMIT_COMPANY_INFO,
  GET_STATE_CITY,
  GET_BANK_DETAIL,
  PLACE_ORDER,
  ADD_DEALERS,
  PAYMENT_ORDER,
  CLEAR_DUES
} from "../actions/types";

const initialState = {
  paymentForm: null,
  companyInfo: null,
  stateCity: null,
  bankDetails: null,
  order: null,
  dealers: null,
  orderPayment: null,
  clearDues: null
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_PAYMENT_FORM:
      return {
        ...state,
        paymentForm: action.payload,
      };

    case SUBMIT_COMPANY_INFO:
      return {
        ...state,
        companyInfo: action.payload,
      };
    case GET_STATE_CITY:
      return {
        ...state,
        stateCity: action.payload,
      };
    case GET_BANK_DETAIL:
      return {
        ...state,
        bankDetails: action.payload,
      };
    case PLACE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case ADD_DEALERS:
      return {
        ...state,
        dealers: action.payload,
      };
    case PAYMENT_ORDER:
      return {
        ...state,
        orderPayment: action.payload,
      };
      case CLEAR_DUES:
        return {
          ...state,
          clearDues: action.payload,
        };
  

    default:
      return state;
  }
};

export default checkoutReducer;
