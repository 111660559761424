import { STOP_LOADER, START_LOADER } from "../actions/types";


const initialState = {
    startLoader: false,
};

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADER:
            return {
                ...state,
                startLoader: action.payload,
            };
        case STOP_LOADER:
            return {
                ...state,
                startLoader: action.payload,
            };


        default:
            return state;
    }
};
export default loaderReducer;
