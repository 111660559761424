import { combineReducers } from "redux";
import {persistReducer} from 'redux-persist'
import errorResponse from "./errorReducer";
import loginReducer from "./loginReducer";
import signupReducer from "./signUpReducer"
import whitepaperReducer from "./whitepaperReducer"
import newspaperReducer from "./newspaperReducer"
import dashboardReducer from "./dashboardReducer"
import productReducer from "./productReducer"
import checkoutReducer from "./checkoutReducer"
import profileReducer from "./profileReducer"
import complaintsReducer from "./complaintsReducer"
import feedbackReducer from "./feedbackReducer"
import teamListReducer from "./teamListReducer"
import serviceRequestReducer from "./serviceRequestReducer"
import liveRatesReducer from "./liveRatesReducer"
import loaderReducer from "./LoaderReducer"
import orderHistoryReducer from "./orderHistoryReducer"

import storage from "redux-persist/lib/storage"


const persistConfig ={
  key: 'root',
  storage,
  whitelist: ["loginResponse","dashboardReducer"]
}
const rootReducer = combineReducers({
  errorResponse,
  loginResponse: loginReducer,
  signupReducer,
  dashboardReducer,
  whitepaperReducer,
  productReducer,
  checkoutReducer,
  newspaperReducer,
  profileReducer,
  feedbackReducer,
  complaintsReducer,
  feedbackReducer,
  teamListReducer,
  serviceRequestReducer,
  liveRatesReducer,
  loaderReducer,
  orderHistoryReducer,
});

export default persistReducer(persistConfig, rootReducer) ;
