import { GET_TEAM_LIST, SAVE_TEAM, UPDATE_TEAM} from "../actions/types";


const initialState = {
    teamList: null,
    teamData:null
};


const teamListReducer = (state = initialState, action) =>{
  switch(action.type){
      case GET_TEAM_LIST:
      return {
          ...state,
          teamList:action.payload
      };
      case SAVE_TEAM:
          return {
              ...state,
              teamData: action.payload
          };
      case UPDATE_TEAM:
          return {
              ...state,
              teamData: action.payload
          };
      default:
      return state;
  }  
};

export default teamListReducer;