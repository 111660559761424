export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GENERATE_REFRESH_TOKEN = "GENERATE_REFRESH_TOKEN";
export const GENERATE_ACCESS_TOKEN = "GENERATE_ACCESS_TOKEN";
export const GET_SPECIES = "GET_SPECIES";
export const CREATE_GUEST_USER_ID ="CREATE_GUEST_USER_ID";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const INTERESTED_SPECIES = "INTERESTED_SPECIES";
export const GET_WHITEPAPER_DETAIL = "GET_WHITEPAPER_DETAIL"
export const GET_ALL_WHITEPAPER ="GET_ALL_WHITEPAPER"
export const GET_ALL_NEWSPAPER ="GET_ALL_NEWSPAPER";
export const GET_NEWS_DETAIL = "GET_NEWS_DETAIL";
export const GET_SEARCH_RESULT ="GET_SEARCH_RESULT"

export const GET_ALL_PRODUCTS ="GET_ALL_PRODUCTS";
export const GET_PRODUCT_DETAIL ="GET_PRODUCT_DETAIL";
export const ADD_PRODUCT_CART = "ADD_PRODUCT_CART";
export const VIEW_PRODUCT_CART ="VIEW_PRODUCT_CART";


export const HEADER_CLASS ="HEADER_CLASS"
export const GLOBAL_SEARCH_WORD ="GLOBAL_SEARCH_WORD"
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const LOGIN_USER ="LOGIN_USER"
//export const DASHBOARD = "DASHBOARD"

export const LOGIN_USER_SUCCESS ="LOGIN_USER_SUCCESS"
export const LOGIN_USER_OTP_SUCCESS ="LOGIN_USER_OTP_SUCCESS"

export const SIGNUP_USER_SUCCESS ="SIGNUP_USER_SUCCESS"
export const SIGNUP_USER_OTP_SUCCESS ="SIGNUP_USER_OTP_SUCCESS"

export const SIGNUP_SUBMIT_PROFILE_SUCCESS ="SIGNUP_SUBMIT_PROFILE_SUCCESS";
export const SIGNUP_USER_ERROR="SIGNUP_USER_ERROR";

export const COUPON_APPLAY ="COUPON_APPLAY";
export const GET_TRANS_CHAGRGES = "GET_TRANS_CHAGRGES";
export const SUBMIT_PAYMENT_FORM = "SUBMIT_PAYMENT_FORM";
export const SUBMIT_COMPANY_INFO = "SUBMIT_COMPANY_INFO";
export const GET_STATE_CITY ="GET_STATE_CITY";
export const GET_SUBSCRIBE ="GET_SUBSCRIBE";
export const GET_ALL_COMPLAINTS ="GET_ALL_COMPLAINTS"
export const GET_ALL_FEEDBACK ="GET_ALL_FEEDBACK"
export const FCM_TOKEN ="FCM_TOKEN"
export const GET_USER_PROFILE_DATA ="GET_USER_PROFILE_DATA";
export const GET_PROFILE_DATA = "GET_PROFILE_DATA"
export const GET_TEAM_LIST ="GET_TEAM_LIST"
export const SAVE_TEAM ="SAVE_TEAM"
export const UPDATE_TEAM ="UPDATE_TEAM"
export const SAVE_COMPLAINTS ="SAVE_COMPLAINTS"
export const SAVE_SERVICE_REQUEST ="SAVE_SERVICE_REQUEST"
export const SAVE_FEEDBACK ="SAVE_FEEDBACK"
export const SAVE_SERVICE_LIST="SAVE_SERVICE_LIST"
export const SAVE_COMMENT="SAVE_COMMENT"


export const GET_BANK_DETAIL = "GET_BANK_DETAIL";
export const LIVE_RATES= "LIVE_RATES"
export const PLACE_ORDER = "PLACE_ORDER";
export const ADD_DEALERS = "ADD_DEALERS";
export const FAV_PRODUCTS_LIST = "FAV_PRODUCTS_LIST"
export const ADD_FAV_PRODUCTS_LIST ="ADD_FAV_PRODUCTS_LIST";
export const ADD_RECENT_PRODUCT = "ADD_RECENT_PRODUCT";
export const VIEW_RECENT_PRODUCT = "VIEW_RECENT_PRODUCT";
export const LIST_REVIEWS = "LIST_REVIEWS";
export const ADD_REVIEWS = "ADD_REVIEWS";
export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const ORDER_HISTORY = "ORDER_HISTORY"
export const SET_ORDER_HISTORY_DETAIL='SET_ORDER_HISTORY_DETAIL';
export const SET_ORDER_HISTORY_LIST='SET_ORDER_HISTORY_LIST';
export const SET_COMPLAINT_COMMENTS = "SET_COMPLAINT_COMMENTS";
export const SAVE_SERVICE_REQUEST_LIST='SAVE_SERVICE_REQUEST_LIST';
export const SET_SERVICE_REQUEST_DETAIL='SET_SERVICE_REQUEST_DETAIL';
export const CLEAR_SERVICE_REQUEST= "CLEAR_SERVICE_REQUEST";

export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const PAYMENT_TYPE = "PAYMENT_TYPE";
export const COUPON_REMOVE = "COUPON_REMOVE";
export const PAYMENT_ORDER = "PAYMENT_ORDER";
export const GET_ALL_RELATED_COMPLAINTS  = "GET_ALL_RELATED_COMPLAINTS";
export const CLEAR_DUES ="CLEAR_DUES";
export const CLAIM_INSURANCE = "CLAIM_INSURANCE";
