import { GET_ALL_WHITEPAPER } from "../actions/types";


const initialState = {
allWhitepaper: null
};

const whitepaperReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_WHITEPAPER:
      return {
        ...state,
        allWhitepaper: action.payload,
      };
      

    default:
      return state;
  }
};
export default whitepaperReducer;
