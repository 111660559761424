import {
  SAVE_SERVICE_REQUEST,
  SAVE_SERVICE_LIST,
  SAVE_SERVICE_REQUEST_LIST,
  SET_SERVICE_REQUEST_DETAIL,
  CLEAR_SERVICE_REQUEST
} from "../actions/types";

const initialState = {
  serviceRequestData: null,
  serviceList: null,
  serviceRequestList: [],
  serviceRequestDetail:null
};

const serviceRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SERVICE_REQUEST:
      return {
        ...state,
        serviceRequestData: action.payload,
      };
    case CLEAR_SERVICE_REQUEST:
      return{
        ...state,
        serviceRequestData: null
      };
    case SAVE_SERVICE_LIST:
      return {
        ...state,
        serviceList: action.payload,
      };
    case SAVE_SERVICE_REQUEST_LIST:
      return {
        ...state,
        serviceRequestList: action.payload.data.requests,
      };
    case SET_SERVICE_REQUEST_DETAIL:
      return {
        ...state,
        serviceRequestDetail: action.payload,
      };
    default:
      return state;
  }
};
export default serviceRequestReducer;
