import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import {store, persistor} from "./store";
import {PersistGate} from "redux-persist/integration/react"
import { ToastContainer, toast } from 'react-toastify';
import "./index.css"
import Loader from "./components/LoaderComponent/Loader";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      {/* <Loader>

    </Loader> */}
        <App />
    <ToastContainer />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
