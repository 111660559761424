import { GET_ALL_COMPLAINTS, SAVE_COMPLAINTS, SAVE_COMMENT, SET_COMPLAINT_COMMENTS ,GET_ALL_RELATED_COMPLAINTS} from "../actions/types";


const initialState = {
    allComplaints: null,
    compSave:null,
    complaintComments:null,
    relComplaints : null
};

const complaintsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_COMPLAINTS:
            return {
                ...state,
                allComplaints: action.payload,
            };
        case SAVE_COMPLAINTS:
            return {
                ...state,
                compSave: action.payload,
            };

        case SAVE_COMMENT:
            let comments = state.allComplaints.filter(complaint => complaint.complaintId == action.payload.data.complaintId)
            let commentList = comments[0];
            let updatedComment = [...commentList.comments, action.payload.data];
            return{
                ...state,
                complaintComments:{
                    ...state.complaintComments,
                    comments: updatedComment
                },
            }
        case  SET_COMPLAINT_COMMENTS:
            return{
                ...state,
                complaintComments:action.payload.data,
            }
            case GET_ALL_RELATED_COMPLAINTS :
                return{
                    ...state,
                    relComplaints : action.payload
                }
        default:
            return state;
    }
};
export default complaintsReducer;
